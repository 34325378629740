import { FC, useRef, useState } from 'react';
import { BigNumber } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { Box, CircularProgress, Dialog, styled, Typography, useTheme } from '@mui/material';

import { useAppSelector } from 'state/hooks';
import { ConnectWallet, ContainedButton } from 'components/common/Button';
import { ContainedInput } from 'components/common/Input';
import { getBalanceInEther, getBalanceInWei } from 'utils/formatBalance';
import { useFarmMasterChef } from 'hooks/useFarmMasterChef';
import { useNotification } from 'hooks/useNotification';

const ModalDialog = styled(Dialog)(() => ({
  '.MuiDialog-container > .MuiPaper-root': {
    borderRadius: '20px',
    maxWidth: '330px',
    width: '100%',
    background: '#2A3139',
    boxShadow:
      '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
    padding: '15px 16px 20px',
  },
}));

// modal header
const ModalHeader = styled(Box)(() => ({}));

const ModalTitle = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '19px',
  lineHeight: '29px',
  textAlign: 'center',
}));

// modal body
const ModalBody = styled(Box)(() => ({
  marginTop: '23px',
  marginBottom: '54px',
}));

const AvaialbleSection = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const AvailableAmount = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '21px',
  marginBottom: '8px',
}));

const AmountInput = styled(ContainedInput)(() => ({
  height: '27px',
}));

// modal footer
const ModalFooter = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
}));

const ActionButton = styled(ContainedButton)(() => ({
  maxWidth: '102px',
  height: '32px',
}));

interface Props {
  info: any;
  modalType: string;
  onClose: () => void;
}

const FarmModal: FC<Props> = ({ info, modalType, onClose }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('');
  const [amountInWei, setAmountInWei] = useState<BigNumber>(BigNumber.from(0));

  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;

  const theme = useTheme();
  const { account } = useWeb3React();
  const { onApproveStakingToken, onApproveReceiptToken, onDeposit, onWithdraw } = useFarmMasterChef(info);
  const { onShowNotification } = useNotification();
  const { name, userInfo, farmType, location } = info;
  const stakingTokenBalance = getBalanceInEther(userInfo?.userStakingTokenBalance || BigNumber.from(0));
  const stakingTokenAllowance = getBalanceInEther(userInfo?.userStakingTokenAllowance || BigNumber.from(0));
  const receiptTokenAllowance = getBalanceInEther(userInfo?.userReceiptTokenAllowance || BigNumber.from(0));
  const stakedBalance = getBalanceInEther(userInfo?.stakedBalance || BigNumber.from(0));

  const isStakingTokenApproved = stakingTokenAllowance >= Number(amount);
  const isReceiptTokenApproved = farmType === 'dealPool' || farmType === 'deadPool2' || farmType === 'deadPool3' ||  receiptTokenAllowance > 0;

  const isDepositInputDisabled = isLoading;
  const isWithdrawInputDisabled = !isReceiptTokenApproved || isLoading;
  const isButtonDisabled =
    isLoading ||
    (modalType === 'DEPOSIT' && amountInWei.gt(userInfo?.userStakingTokenBalance || BigNumber.from(0))) ||
    (modalType === 'WITHDRAW' && amountInWei.gt(userInfo?.stakedBalance || BigNumber.from(0)));

  const handleClose = () => {
    onClose();
  };

  const reset = () => {
    setAmount('');
    setAmountInWei(BigNumber.from(0));
  };

  // aprpove or deposit
  const handleApproveOrDeposit = async () => {
    setLoading(true);
    if (!isStakingTokenApproved) {
      // 1. Approve staking token logic
      try {
        await onApproveStakingToken();
        reset();
        onShowNotification({
          title: 'Transaction success',
          description: 'Approve staking token',
          hasView: true,
          txHash: pendingTxHashRef.current,
        });
      } catch (err) {
        onShowNotification({ title: 'Transaction failed', description: 'Approve staking token' });
        console.log('Approve Staking Token Error: ', err);
      }
    } else {
      // 2. Deposit logic
      try {
        await onDeposit(amountInWei);
        reset();
        onShowNotification({
          title: 'Transaction success',
          description: 'Deposit staking token',
          hasView: true,
          txHash: pendingTxHashRef.current,
        });
      } catch (err) {
        onShowNotification({ title: 'Transaction failed', description: 'Deposit staking token' });
        console.log('Deposit Error: ', err);
      }
    }

    setLoading(false);
  };

  // withdraw
  const handleWithdraw = async () => {
    setLoading(true);

    if (!isReceiptTokenApproved) {
      // 1. Approve receipt token (only for smeltRewardPool)
      try {
        await onApproveReceiptToken();
        reset();

        onShowNotification({
          title: 'Transaction success',
          description: 'Approve Receipt Token',
          hasView: true,
          txHash: pendingTxHashRef.current,
        });
      } catch (err) {
        onShowNotification({ title: 'Transaction Failed', description: 'Approve Receipt Token' });

        console.log('Approve Receipt Token Error: ', err);
      }
    } else {
      // 2. Withdraw logic
      try {
        await onWithdraw(amountInWei);
        reset();

        onShowNotification({
          title: 'Transaction Success',
          description: 'Withdraw Staking Token',
          hasView: true,
          txHash: pendingTxHashRef.current,
        });
      } catch (err) {
        onShowNotification({ title: 'Transaction Failed', description: 'Withdraw Staking Token' });
        console.log('Withdraw Error: ', err);
      }
    }
    setLoading(false);
  };

  const onConfirm = () => {
    if (modalType === 'DEPOSIT') {
      handleApproveOrDeposit();
    }
    if (modalType === 'WITHDRAW') {
      handleWithdraw();
    }
  };

  function isNumeric(n: any) {
    return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
  }

  const onChangeAmount = (e: any) => {
    if (!isNumeric(e.target.value)) return;
    const newAmount = e.target.value;
    setAmount(newAmount);
    setAmountInWei(getBalanceInWei(Number(newAmount).toString() || '0'));
  };

  const onMaxDeposit = () => {
    setAmount(stakingTokenBalance.toString());
    setAmountInWei(userInfo?.userStakingTokenBalance || BigNumber.from(0));
  };

  const onMaxWithdraw = () => {
    setAmount(stakedBalance.toString());
    setAmountInWei(userInfo?.stakedBalance || BigNumber.from(0));
  };

  const getButtonText = () => {
    if (modalType === 'WITHDRAW') {
      if (isReceiptTokenApproved) {
        return 'Withdraw';
      }
      return userInfo?.userReceiptTokenAllowance ? 'Approve' : 'Approve';
    }
    if (modalType === 'DEPOSIT') {
      if (isStakingTokenApproved) {
        return 'Deposit';
      }
      return userInfo?.userStakingTokenAllowance ? 'Approve' : 'Approve';
    }
    return 'Deposit';
  };

  // deposit modal content
  if (modalType === 'DEPOSIT') {
    return (
      <ModalDialog maxWidth="xs" onClose={handleClose} open theme={theme}>
        {/* header */}
        <ModalHeader>
          <ModalTitle>{`Deposit ${name}`}</ModalTitle>
        </ModalHeader>
        {/* body */}
        <ModalBody>
          <AvaialbleSection>
            <AvailableAmount>
              {`Available ${location === 'farm' ? 'LP' : name}: ${stakingTokenBalance.toFixed(2)} `}
            </AvailableAmount>
          </AvaialbleSection>
          <AmountInput
            disabled={isDepositInputDisabled}
            onChange={onChangeAmount}
            onClickMax={onMaxDeposit}
            value={amount}
          />
        </ModalBody>
        {/* footer */}
        <ModalFooter>
          {account ? (
            <ActionButton disabled={isButtonDisabled} onClick={onConfirm} sx={{ maxWidth: '102px' }}>
              {isLoading ? <CircularProgress size={20} sx={{ color: 'black' }} /> : getButtonText()}
            </ActionButton>
          ) : (
            <ConnectWallet width={100} />
          )}
        </ModalFooter>
      </ModalDialog>
    );
  }

  // withdraw modal content
  return (
    <ModalDialog maxWidth="xs" onClose={handleClose} open theme={theme}>
      {/* header */}
      <ModalHeader>
        <ModalTitle>{`Withdraw ${name}`}</ModalTitle>
      </ModalHeader>
      {/* body */}
      <ModalBody>
        <AvaialbleSection>
          <AvailableAmount>{`LP Staked: ${stakedBalance.toFixed(2)} `}</AvailableAmount>
        </AvaialbleSection>
        <AmountInput
          disabled={isWithdrawInputDisabled}
          onChange={onChangeAmount}
          onClickMax={onMaxWithdraw}
          value={amount}
        />
      </ModalBody>
      {/* footer */}
      <ModalFooter>
        {account ? (
          <ActionButton disabled={isButtonDisabled} onClick={onConfirm} sx={{ maxWidth: '102px' }}>
            {isLoading ? <CircularProgress size={20} sx={{ color: 'black' }} /> : getButtonText()}
          </ActionButton>
        ) : (
          <ConnectWallet width={100} />
        )}
      </ModalFooter>
    </ModalDialog>
  );
};

export default FarmModal;
