import { getBalanceInEther } from 'utils/formatBalance';
import { getFarms, getMasterChefAbi, getMasterChefAddress, getRewardEmissionRateFunctionName } from 'utils/farms';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import LpPairAbi from 'config/abi/LpPair.json';
import multicall from 'utils/multicall';
import { FarmCategory } from 'types/farm';

// fetch reward pool farms (smelt reward pools and dead pools)
export const fetchPoolFarms = async (chainId: string): Promise<any> => {
  try {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const farms = getFarms(selectedChainid);

    // get farm onchain info
    const farmsOnchainData = await Promise.all(
      farms.map(async (row: any) => {
        const masterChefAddress = getMasterChefAddress(selectedChainid, row.farmType, row.category);
        if (!masterChefAddress) return { ...row };
        const rewardEmissionRateFunctionName = getRewardEmissionRateFunctionName(row.farmType, row.category);

        if (row.category === FarmCategory.TEAM) {
          return {
            ...row,
            poolWeight: 0,
            tvl: 0,
            rewardPerSecond: 0,
          };
        }

        let poolWeight = 0;
        let rewardPerSecond = 0;
        if (rewardEmissionRateFunctionName) {
          const [info, totalAllocPoint, rewardPerSecondRaw] = await multicall(
            getMasterChefAbi(row.farmType, row.category),
            [
              {
                address: masterChefAddress,
                name: 'poolInfo',
                params: [row.poolId],
              },
              {
                address: masterChefAddress,
                name: 'totalAllocPoint',
              },
              {
                address: masterChefAddress,
                name: rewardEmissionRateFunctionName,
              },
            ]
          );

          const allocPoint = getBalanceInEther(info.allocPoint);
          const totalAllocPointRaw = getBalanceInEther(totalAllocPoint);
          rewardPerSecond = getBalanceInEther(rewardPerSecondRaw);
          poolWeight = allocPoint / totalAllocPointRaw;
        } else {
          const [info, totalAllocPoint] = await multicall(getMasterChefAbi(row.farmType, row.category), [
            {
              address: masterChefAddress,
              name: 'poolInfo',
              params: [row.poolId],
            },
            {
              address: masterChefAddress,
              name: 'totalAllocPoint',
            },
          ]);

          const allocPoint = getBalanceInEther(info.allocPoint);
          const totalAllocPointRaw = getBalanceInEther(totalAllocPoint);
          poolWeight = allocPoint / totalAllocPointRaw;
          rewardPerSecond = 0.00115;
        }

        // BBOND pool (staking token is not LP)
        if (row.stakingToken0 === '0x0000000000000000000000000000000000000000') {
          const [stakingTokenBalanceMC] = await multicall(LpPairAbi, [
            // Balance of LP tokens in the master chef contract
            {
              address: row.stakingToken,
              name: 'balanceOf',
              params: [masterChefAddress],
            },
          ]);
          const poolTvl = getBalanceInEther(stakingTokenBalanceMC);
          return {
            ...row,
            poolWeight,
            tvl: poolTvl,
            rewardPerSecond,
          };
        }

        // LP pool
        const calls = [
          // Balance of token in the LP contract
          {
            address: row.stakingToken0,
            name: 'balanceOf',
            params: [row.stakingToken],
          },
          // Balance of quote token on LP contract
          {
            address: row.stakingToken1,
            name: 'balanceOf',
            params: [row.stakingToken],
          },
          // Balance of LP tokens in the master chef contract
          {
            address: row.stakingToken,
            name: 'balanceOf',
            params: [masterChefAddress],
          },
          // Total supply of LP tokens
          {
            address: row.stakingToken,
            name: 'totalSupply',
          },
          // Token0 decimals
          {
            address: row.stakingToken0,
            name: 'decimals',
          },
          // Token1 decimals
          {
            address: row.stakingToken1,
            name: 'decimals',
          },
        ];

        const [
          token0BalanceLP,
          token1BalanceLP,
          stakingTokenBalanceMC,
          stakingTokenTotalSupply,
          token0Decimals,
          token1Decimals,
        ] = await multicall(LpPairAbi, calls);

        const tokenSupply = getBalanceInEther(token0BalanceLP);
        const tokenSupply1 = getBalanceInEther(token1BalanceLP);
        const totalSupply = getBalanceInEther(stakingTokenTotalSupply)
        const tokenInLP = tokenSupply/ Number(totalSupply);
        // TVL
        const poolTvl = getBalanceInEther(stakingTokenBalanceMC);
        // Ratio in % a LP tokens that are in staking, vs the total number in circulation
        const stakingLpTokenRatio = poolTvl / getBalanceInEther(stakingTokenTotalSupply);

        // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
        const token0Amount =
          // eslint-disable-next-line no-restricted-properties
          getBalanceInEther(token0BalanceLP) * Math.pow(10, 18 - Number(token0Decimals)) * stakingLpTokenRatio;
        const token1Amount =
          // eslint-disable-next-line no-restricted-properties
          getBalanceInEther(token1BalanceLP) * Math.pow(10, 18 - Number(token1Decimals)) * stakingLpTokenRatio;

        return {
          ...row,
          token0Amount,
          token1Amount,
          poolWeight,
          tvl: poolTvl,
          rewardPerSecond,
          tokenInLP
        };
      })
    );

    return farmsOnchainData;
  } catch (error: any) {
    return [];
  }
};

export const fetchGlobalFarmData = async (chainId: string): Promise<any> => {
  const smeltRewardPoolFarmsData = await fetchPoolFarms(chainId);

  return {
    data: [...smeltRewardPoolFarmsData],
  };
};
