import { getBalanceInEther } from 'utils/formatBalance';
import { getMasterChefAbi, getNftFarms, getRewardEmissionRateFunctionName } from 'utils/farms';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import GodNftAbi from 'config/abi/GodNft.json';
import multicall from 'utils/multicall';
import { FarmCategory } from 'types/farm';

// fetch nft farms
export const fetchNftFarms = async (chainId: string): Promise<any> => {
  try {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const farms = getNftFarms(selectedChainid);
    // get farm onchain info
    const farmsOnchainData = await Promise.all(
      farms.map(async (row: any) => {
        const { masterChefAddress } = row;
        if (!masterChefAddress) return { ...row };
        if( row.isFake || row.category === FarmCategory.INVISIBLE ) {
          return {
            ...row,
            poolWeight: 0,
            tvl: -1,
            rewardPerSecond: 0,
          }
        }
        const rewardEmissionRateFunctionName = getRewardEmissionRateFunctionName(row.farmType, row.category);

        let poolWeight = 0;
        let rewardPerSecond = 0;
        if (rewardEmissionRateFunctionName) {
          const [info, totalAllocPoint, rewardPerSecondRaw] = await multicall(
            getMasterChefAbi(row.farmType, row.category),
            [
              {
                address: masterChefAddress,
                name: 'poolInfo',
                params: [row.poolId],
              },
              {
                address: masterChefAddress,
                name: 'totalAllocPoint',
              },
              {
                address: masterChefAddress,
                name: 'smeltPerSecond',
              },
            ]
          );

          const allocPoint = getBalanceInEther(info.allocPoint);
          const totalAllocPointRaw = getBalanceInEther(totalAllocPoint);
          rewardPerSecond = getBalanceInEther(rewardPerSecondRaw);
          poolWeight = allocPoint / totalAllocPointRaw;
        } else {
          const [info, totalAllocPoint] = await multicall(getMasterChefAbi(row.farmType, row.category), [
            {
              address: masterChefAddress,
              name: 'poolInfo',
              params: [row.poolId],
            },
            {
              address: masterChefAddress,
              name: 'totalAllocPoint',
            },
          ]);

          const allocPoint = getBalanceInEther(info.allocPoint);
          const totalAllocPointRaw = getBalanceInEther(totalAllocPoint);
          rewardPerSecond = 0.00115;
          poolWeight = allocPoint / totalAllocPointRaw;
        }

        const calls = [
          // number of staked NFTs in masterchef contract
          {
            address: row.stakingToken,
            name: 'balanceOf',
            params: [masterChefAddress],
          },
        ];

        const [stakingTokenBalanceMC] = await multicall(GodNftAbi, calls);
        const tokenBalance = stakingTokenBalanceMC[0].toNumber();
        // if( row.poolId === 5 ){
        //   // Temporary for show
        //   tokenBalance = 0
        // }

        return {
          ...row,
          poolWeight,
          tvl: tokenBalance,
          rewardPerSecond,
        };
      })
    );

    return farmsOnchainData;
  } catch (error: any) {
    console.log(error)
    return [];
  }
};

export const fetchGlobalFarmData = async (chainId: string): Promise<any> => {
  const smeltRewardPoolFarmsData = await fetchNftFarms(chainId);

  return {
    data: [...smeltRewardPoolFarmsData],
  };
};
