import { ethers } from 'ethers';
import multicall from 'utils/multicall';
import TwistedNodeAbi from 'config/abi/TwistedNode.json';
import { getNodes } from 'utils/nodes';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';

export const fetchGlobalNodeData = async (chainId: string): Promise<any> => {
  const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
  const nodes = getNodes(selectedChainid);

  try {
    const nodesData = await Promise.all(
      nodes.map(async (node: any) => {
        const [nodeTvlRaw] = await multicall(TwistedNodeAbi, [
          {
            address: node.address,
            name: 'totalRewardDebtInDollars',
            params: [],
          },
        ]);

        return {
          ...node,
          tvl: Number(ethers.utils.formatUnits(nodeTvlRaw[0].toString(), 6)),
        };
      })
    );

    return {
      data: nodesData,
    };
  } catch (error: any) {
    return {
      data: [],
    };
  }
};
