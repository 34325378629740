import { farmInfos, masterChefInfos, nftFarmInfos } from 'config/constants/farms';
import { FarmCategory } from 'types/farm';
import SmeltRewardPoolAbi from 'config/abi/SmeltRewardPool.json';
import RetiredSmeltRewardPoolAbi from 'config/abi/SmeltRewardPool(Retired).json';
import TestSmeltRewardPoolAbi from 'config/abi/SmeltRewardPool(Test).json';

import DeadPoolAbi from 'config/abi/DeadPool.json';

export const getFarms = (chainId: number) => {
  const tokens = farmInfos.filter((row: any) => Number(chainId) === Number(row.chainId));
  return tokens;
};

export const getNftFarms = (chainId: number) => {
  const tokens = nftFarmInfos.filter((row: any) => Number(chainId) === Number(row.chainId));
  return tokens;
};

export const getMasterChefAddress = (chainId: number, type: string, category: FarmCategory) => {
  const masterChef = masterChefInfos.find(
    (row: any) => Number(chainId) === Number(row.chainId) && type === row.type && category === row.category
  );

  return masterChef?.address;
};

export const getMasterChefAbi = (type: string, category: FarmCategory) => {
  if (type === 'smeltRewardPool') {
    if (category === FarmCategory.ACTIVE || category === FarmCategory.ACTIVE_RETIRED) return SmeltRewardPoolAbi;
    if (category === FarmCategory.RETIRED) return RetiredSmeltRewardPoolAbi;
    if (category === FarmCategory.TEAM) return RetiredSmeltRewardPoolAbi;
    if (category === FarmCategory.TEST) return TestSmeltRewardPoolAbi;
  }
  if (type === 'dealPool' || type === 'deadPool2' || type === 'deadPool3') {
    return DeadPoolAbi;
  }
  return SmeltRewardPoolAbi;
};

export const getRewardEmissionRateFunctionName = (type: string, category: FarmCategory): string | null => {
  if (type === 'smeltRewardPool') {
    if (category === FarmCategory.ACTIVE) return null;
    if (category === FarmCategory.RETIRED) return 'smeltPerSecond';
    if (category === FarmCategory.TEAM) return 'smeltPerSecond';
    if (category === FarmCategory.TEST) return 'smeltPerSecond';
  }
  if (type === 'dealPool' || type === 'deadPool2' || type === 'deadPool3') {
    return 'rewardPerSecond';
  }
  return null;
};
