import { createSlice } from '@reduxjs/toolkit';

import { FarmInfo } from 'types/farm';
import { fetchGlobalFarmData } from './fetchGlobalFarms';
import { fetchFarmUserData, fetchUserFarmDataSingle, fetchUserTokenDataSingle } from './fetchUserFarms';

interface FarmState {
  data: FarmInfo[];
  loaded: boolean;
}

const initialState: FarmState = {
  data: [],
  loaded: false
};

export const farmSlice = createSlice({
  name: 'farm',
  initialState,
  reducers: {
    reset: (state) => {
      state.data = [];
      state.loaded = false;
    },

    setFarmGlobalData: (state, action) => {
      state.data = action.payload.data.map((row: any, i: number) => ({
        ...row,
        userInfo: state.data[i]?.userInfo || {},
      }));
      state.loaded = true;
    },

    setFarmUserData: (state, action) => {
      const { data } = action.payload;
    
      // update farms
      state.data = state.data.map((row: FarmInfo, index: number) => {
        try{
          if (row.name === data[index].name) {
            return {
              ...row,
              userInfo: {
                ...row.userInfo,
                ...data[index],
              },
            };
          }
        }
        catch(err){
          console.log(err)
        }
        return row;
      });
    },

    setFarmUserTokenData: (state, action) => {
      const { data } = action.payload;
      try{
        state.data = state.data.map((row: FarmInfo) => {
          if (row.name === data.name) {
            return {
              ...row,
              userInfo: {
                ...row.userInfo,
                userStakingTokenAllowance: data.userStakingTokenAllowance,
                userStakingTokenBalance: data.userStakingTokenBalance,
                userReceiptTokenAllowance: data.userReceiptTokenAllowance,
              },
            };
          }
          return row;
        });
      }
      catch(err){
        // 
      }
    },

    setFarmUserDepositData: (state, action) => {
      const { data } = action.payload;

      state.data = state.data.map((row: FarmInfo) => {
        if (row.name === data.name) {
          return {
            ...row,
            userInfo: {
              ...row.userInfo,
              stakedBalance: data.stakedBalance,
              rewardTokenBalance: data.rewardTokenBalance,
            },
          };
        }
        return row;
      });
    },
  },
});

export const { reset, setFarmGlobalData, setFarmUserData, setFarmUserTokenData, setFarmUserDepositData } =
  farmSlice.actions;

// fetch global farm info
export const fetchFarmGlobalDataAsync =
  (chainId: string) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchGlobalFarmData(chainId);

    dispatch(
      setFarmGlobalData({
        data,
      })
    );
  };

// fetch farm user token info
export const fetchFarmUserTokenDataAsync =
  (account: string, chainId: string, farm: any) =>
  async (dispatch: any): Promise<void> => {
    const data = await fetchUserTokenDataSingle(account, chainId, farm);

    dispatch(
      setFarmUserTokenData({
        data,
      })
    );
  };

// fetch farm user token info
export const fetchFarmUserDepositDataAsync =
  (account: string, chainId: string, farm: any) =>
  async (dispatch: any): Promise<void> => {
    const data = await fetchUserFarmDataSingle(account, chainId, farm);

    dispatch(
      setFarmUserDepositData({
        data,
      })
    );
  };

// fetch global farm user info
export const fetchFarmGlobalUserDataAsync =
  (account: string, chainId: string) =>
  async (dispatch: any): Promise<void> => {
    const data = await fetchFarmUserData(account, chainId);

    dispatch(
      setFarmUserData({
        data,
      })
    );
  };

export default farmSlice.reducer;
