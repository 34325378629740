import { getBalanceInEther } from 'utils/formatBalance';
import { getLiquidity } from 'utils/liquidity';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import LpPairAbi from 'config/abi/LpPair.json';
import ERC20 from 'config/abi/Erc20.json';

import multicall from 'utils/multicall';
import { getTreasuryAddress } from 'utils/addressHelpers';
import { getTreasuryAbi } from 'utils/stakings';
import { BigNumber } from 'ethers';

// fetch reward pool farms (smelt reward pools and dead pools)
export const fetchTheatreData = async (chainId: string): Promise<any> => {
  try {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const treasuryAddr = getTreasuryAddress(selectedChainid);

    const [basedPriceInLastTwap, basedPrice, bondPurchasable] = await multicall(getTreasuryAbi(), [
        {
          address: treasuryAddr,
          name: 'getBasedUpdatedPrice',
          params: [],
        },
        {
            address: treasuryAddr,
            name: 'getBasedPrice',
            params: [],
        },
        {
            address: treasuryAddr,
            name: 'getBurnableBasedLeft',
            params: [],
        }
      ]);
      const bbasedPrice = getBalanceInEther(basedPrice);
      const bbondPurchasable = getBalanceInEther(bondPurchasable);
      const bbasedLastTwap = getBalanceInEther(basedPriceInLastTwap);

      const DECIMALS_18 = BigNumber.from(10).pow(18);
      const BOND_REDEEM_PRICE = 1.01;
      const BOND_REDEEM_PRICE_BN = DECIMALS_18.mul(101).div(100);
    
      const isBondRedeemable = bbasedLastTwap > BOND_REDEEM_PRICE;
      const isBondPurchasable = Number(bbasedLastTwap) / 1e18 < 1.0;
      return { basedPrice: bbasedPrice, basedPriceInLastTwap: bbasedLastTwap, burnableBasedLeft: bbondPurchasable, isBondPurchaseble: true, isBondRedeemable}
            
  } catch (error: any) {
    return {basedPrice: 0, basedPriceInLastTwap: 0, burnableBasedLeft: 0}
  }
};

export const fetchGlobalTheatreData = async (chainId: string): Promise<any> => {
  const data = await fetchTheatreData(chainId);
  return {
    data,
  };
};
